<template>
  <div class="lists-wrapper__lists">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info flex-center">
          <span class="grid-heading">
            <h1>{{ $t('Packer') }}</h1>
            <span class="entries">{{ NumberFormat(list.entries) }} {{ $t(`entr${list.entries == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-actions">
            <div class="grid-date">
              <p class="grid-heading" style="color: inherit;">{{ $t('Start date') }}</p>
              <div class="date-range flex-row">
                <div class="input">
                  <VueCtkDateTimePicker id="list-period-date-range-start" @input="SearchDate({start: $event})" :value="list.period.date.range.start" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                </div>
              </div>
            </div>
            <div class="grid-date" style="margin-left: 20px;">
              <p class="grid-heading" style="color: inherit;">{{ $t('End date') }}</p>
              <div class="date-range flex-row">
                <div class="input">
                  <VueCtkDateTimePicker id="list-period-date-range-end" @input="SearchDate({end: $event})" :value="list.period.date.range.end" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                </div>
              </div>
            </div>
            <a class="button" @click.prevent="GetList" href="">{{ $t('Apply') }}</a>
            <div class="toggle cost">
              <span class="label-text">{{ $t('Currency') }}</span>
              <div class="checkbox-toggle">
                <input @click="ToggleCost" type="checkbox" :checked="list.cost">
                <span class="toggle"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="simple-list">
          <ul class="simple-list__head">
            <li class="list-col list-cell packer-name sort" :class="list.sort.column == 'name' && list.sort.by == 'string' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'string', 'user_name', 'name')">{{ $t('Name') }}</li>
            <li class="list-col list-cell orders-pr-hour sort" :class="list.sort.column == 'efficiency' && list.sort.by == 'number' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'number', 'orders_pr_hour', 'efficiency')">
              <div class="state-wrapper">
                <span class="state negative" :data-state="list.items.filter(item => item.orders_pr_hour < 35).length"></span>
                <span class="state positive" :data-state="list.items.filter(item => item.orders_pr_hour >= 35).length"></span>
              </div>
              {{ $t('Target') }}
            </li>
            <li class="list-col list-cell commission sort" :class="list.sort.column == 'commission' && list.sort.by == 'number' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'number', 'user_commission_pay', 'commission')">{{ $t('Commission') }}</li>
            <li class="list-col list-cell order-count sort" :class="list.sort.column == 'orders' && list.sort.by == 'number' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'number', 'order_count', 'orders')">{{ $t('Orders') }}</li>
            <li class="list-col list-cell product-count sort" :class="list.sort.column == 'products' && list.sort.by == 'number' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'number', 'product_count_total', 'products')">{{ $t('Products') }}</li>
            <li class="list-col list-cell time-pr-order sort" :class="list.sort.column == 'order_time' && list.sort.by == 'number' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'number', 'time_pr_order', 'order_time')">{{ $t('Time per order') }}</li>
            <li class="list-col list-cell time-pr-product sort" :class="list.sort.column == 'product_time' && list.sort.by == 'number' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'number', 'time_pr_product', 'product_time')">{{ $t('Time per product') }}</li>
            <li class="list-col list-cell cost-pr-product sort" :class="list.sort.column == 'product_cost' && list.sort.by == 'number' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'number', 'cost_pr_product', 'product_cost')">{{ $t('Cost per product') }}</li>
            <li class="list-col list-cell total-time-tracked sort" :class="list.sort.column == 'time_used' && list.sort.by == 'number' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'number', 'time_used_seconds', 'time_used')">{{ $t('Total time') }}</li>
            <li class="list-col list-cell total-cost-price sort" :class="list.sort.column == 'order_cost' && list.sort.by == 'number' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'number', 'cost_pr_order', 'order_cost')">{{ $t('Total cost') }}</li>
          </ul>
          <ul class="simple-list__body odd-even">
            <li :data-id="item.user_id" :key="index" v-for="(item, index) in list.items">
              <ul class="list-row head">
                <li class="list-col list-cell packer-name">{{ item.user_name }}</li>
                <li class="list-col list-cell orders-pr-hour">
                  <div class="state lowercase" :class="[item.orders_pr_hour >= 35 ? 'positive' : 'negative']" :title="(item.orders_pr_hour >= 35 ? 'Above' : 'Below') + ' 35 orders pr. hour'">
                    {{ NumberFormat(item.orders_pr_hour) }} {{ $t('oph') }}
                  </div>
                </li>
                <li class="list-col list-cell commission cost" :class="{show: list.cost}">Kr. {{ CurrencyFormat(item.user_commission_pay) }}</li>
                <li class="list-col list-cell order-count">{{ NumberFormat(item.order_count) }}</li>
                <li class="list-col list-cell product-count">{{ NumberFormat(item.product_count_total) }}</li>
                <li class="list-col list-cell time-pr-order">{{ TimeFormat(item.time_pr_order) }}</li>
                <li class="list-col list-cell time-pr-product">{{ TimeFormat(item.time_pr_product) }}</li>
                <li class="list-col list-cell cost-pr-product cost" :class="{show: list.cost}">Kr. {{ CurrencyFormat(item.cost_pr_product) }}</li>
                <li class="list-col list-cell total-time-tracked">{{ TimeFormat(item.time_used_seconds) }}</li>
                <li class="list-col list-cell total-cost-price cost" :class="{show: list.cost}">Kr. {{ CurrencyFormat(item.cost_pr_order) }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="loading" v-if="loading">
        <div class="loading-animation">
          <div class="loading-element">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { BPA }                    from '@/helpers/BPA';
  import { Tool }                   from '@/helpers/Tool';

  export default {
    name: 'StatisticsPackerStats',
    mixins: [BPA, FormElementsBehaviour, TableElementsBehaviour],
    components: {},
    data() {
      return {
        loading: false,
        list: {
          entries: 0,
          items: [],
          period: {
            date: {
              disabled: false,
              range: {
                start: null,
                end: null
              }
            }
          },
          sort: {
            column: 'name',
            prop: 'user_name',
            dir: 'asc',
            by: 'string'
          },
          cost: true
        }
      }
    },
    created() {
      this.PreSelectDateRange();
      this.GetList();
    },
    mounted() {
      
    },
    destroyed() {

    },
    computed: {
      
    },
    methods: {
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      DateToUTC(date) {
        return Tool.DateToUTC(date);
      },
      DateToISO(date) {
        return Tool.DateToISO(date);
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      CurrencyFormat(number) {
        return Tool.CurrencyFormat(number);
      },
      Empty(element) {
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
      },
      PreSelectDateRange() {
        let start_date = new Date();
        const end_date = new Date();
        const last_week = start_date.getDate() - 7;
        start_date.setDate(last_week);
        this.list.period.date.range = {
          start: this.DateToISO(start_date),
          end: this.DateToISO(end_date)
        }
      },
      async SearchDate(date) {
        let type = Object.keys(date)[0];
        let range = this.list.period.date.range;
        if (date[type] && !range[type]) {
          let time = (type == 'start' ? '00:00' : '23:59') + ':00';
          date[type] = date[type].split(' ')[0] + ' ' + time;
        }
        range[type] = date[type];
      },
      GetList() {
        const period = this.list.period;
        const range = period.date.range;
        const params = {
          start_date: new Date(range.start),
          end_date: new Date(range.end)
        }
        if (range.start && range.end) {
          if (params.end_date > params.start_date) {
            params.start_date = this.DateToUTC(params.start_date);
            params.end_date = this.DateToUTC(params.end_date);
            this.loading = true;
            BPA.api.GetPackerStats(params).then(response => {
              return BPA.api.response({response, return: 'json'});
            }).then(response => {
              this.loading = false;
              if (!response.ok) return;
              let items = response.result || {};
              this.list.entries = Object.keys(items).length;
              for (let id in items) {
                const item = items[id];
                item.time_pr_order = item.time_used_seconds / item.order_count;
                item.time_pr_product = item.time_used_seconds / item.product_count_total;
                item.orders_pr_hour = this.OrdersPrHour(item.order_count, item.time_used_seconds);
                item.cost_pr_order = item.user_commission_pay * item.order_count;
                item.cost_pr_product = item.cost_pr_order / item.product_count_total;
              } 
              this.list.items = Object.values(items);
              const sort = this.list.sort;
              this.Sort(this.list, 'items', sort.by, sort.prop, sort.column, sort.dir);
              //console.log(JSON.parse(JSON.stringify(this.list.items)))
            }).catch(e => e);
          }
        }
      },
      OrdersPrHour(order_count, time_used_seconds) {
        let hours = (time_used_seconds / 3600).toFixed(2);
        let orders_pr_hour = order_count / hours;
        return Math.round(orders_pr_hour * 100) / 100;
      },
      TimeFormat(seconds = 0, all_parts = false) {
        let time = Tool.TimeFormat(seconds, all_parts).split(' ').map(x => x.replace(/\D/g, '') + this.$t(x.replace(/\d/g, ''))).join(' ');
        return time ? time : ('00' + this.$t('s'));
      },
      /*
      TimeFormat(secs) {
        let time = [];
        let total = secs;
        let hours = Math.floor(total / 3600);
        total = Math.floor(total % 3600);
        let minutes = Math.floor(total / 60);
        let seconds = total % 60;
        [hours, minutes, seconds].forEach((part, i) => {
          const unit = i == 0 ? 'h' : i == 1 ? 'm' : 's';
          if (unit == 'h' && !hours) return;
          time[i] = String(part).padStart(2, '0') + unit;
        });
        return time.join(',').replace(/,/g, ' ').trim();
      },
      */
      ToggleCost(e) {
        this.list.cost = e.target.checked;
      },
      Sort(obj, list, by, prop = '', col = '', dir) {
        const sort = obj.sort;
        const items = obj[list];
        const direction = (a, b) => {
          if (sort.dir == 'asc') {
            return a < b ? -1 : a > b ? 1 : 0;
          } else {
            return b < a ? -1 : b > a ? 1 : 0;
          }
        }
        if (sort.by == by) {
          sort.dir = sort.dir == 'desc' ? 'asc' : 'desc';
        }
        if (dir) sort.dir = dir;
        sort.by = by;
        sort.prop = prop;
        sort.column = col;
        items.sort((a, b) => {
          switch (by) {
            case 'date': 
              a = new Date(a[prop]);
              b = new Date(b[prop]);
              break;
            case 'number':
              a = Number(a[prop]);
              b = Number(b[prop]);
              break;
            case 'string':
              a = a[prop].toUpperCase();
              b = b[prop].toUpperCase();
              break;
            default:
              a = a[prop];
              b = b[prop];
          }
          return direction(a, b);
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/colors';
  @import '../../../assets/style/variables/icons';
  
  .grid-info {
    flex-wrap: nowrap;

    .grid-heading {
      flex-shrink: 0;
    }

    &.flex-center {
      align-items: center;
      //margin-bottom: 85px;

      .grid-actions {
        width: 100%;
        justify-content: flex-end;
        margin: 0 0 0 calc(10% + 20px);

        .select.period {
          width: 100%;
          max-width: 15em;
        }

        .date-range {
          width: 100%;

          .input {
            width: 100%;
          }
        }

        .button {
          height: 100%;
          flex-shrink: 0;
          background-color: $green;
        }

        > * + * {
          margin-left: 20px;
        }
      }
    }
  }

  .simple-list {
    flex-direction: column;
    border-top: 2px solid #d3d3d3;

    &, ul {
      display: flex;
      list-style-type: none;
    }

    &__head {
      top: 0;
      z-index: 2;
      padding: 10px 10px 0;
      font-weight: 600;
      position: sticky;
      background-color: white;
      border-bottom: 2px solid lightGray;

      .list-col {
        width: 100%;
        display: flex;
        line-height: 1;
        align-items: flex-end;
        padding-bottom: 6px;

        & + .list-col {
          margin-left: 10px;
        }
      }
    }

    &__body {
      flex-direction: column;
      background: linear-gradient(to bottom, white, white 50%, lightGray 50%, lightGray);
      background-size: 100% 90px;
      
      &, ul {
        position: relative;
      }

      .list {
        &-row {
          &:not(.body) {
            //padding: 10px;
            height: 45px;
            padding: 0 10px;
            //border-bottom: 2px solid #eaeaea;

            &.head {
              top: 50px;
              z-index: 1;
              position: sticky;
              align-items: center;
              //background-color: white;
              //background-color: rgba(255, 255, 255, 0.9);

              .list-col + .list-col {
                margin-left: 10px;
              }
              
              &.toggle {
                align-items: center;

                &::before {
                  width: 18px;
                  height: 18px;
                  content: '';
                  flex-shrink: 0;
                  position: absolute;
                  display: block;
                  mask-size: contain;
                  mask-repeat: no-repeat;
                  mask-position: center;
                  mask-image: $caret-down;
                  background-color: $textFont;
                  transition: transform 0.15s ease;
                  transform-origin: center center;
                }

                .packer-name:not(.nested) {
                  text-transform: capitalize;
                }

                &:hover {
                  color: $blue;
                  cursor: pointer;

                  &::before {
                    background-color: $blue;
                  }

                  .packer-name:not(.nested) {
                    color: $blue;
                    text-decoration: underline;
                  }
                }
              }

              &.expanded {
                color: $blue;
                font-weight: 600;

                & ~ .body:not(.clone) {
                  height: auto;
                }
              }

              &.collapsed {
                &::before {
                  //mask-image: $caret-down;
                  transform: rotate(-90deg);
                }

                & ~ .body:not(.clone) {
                  height: 0;
                }
              }
            }
          }

          &.body {
            height: auto;
            overflow: hidden;
            flex-direction: column;
            justify-content: flex-end;
            
            &.animate {
              transition: height 0.15s ease;
            }

            &.clone {
              opacity: 0;
              position: relative;
            }
          }

          &.nested {
            padding-left: 10px;
            padding-right: 10px;
            align-items: center;
            background-color: #f7f7f7;
            //background-color: rgba(247, 247, 247, 0.95);
          }
        }

        &-col {
          width: 100%;
          flex-direction: column;

          &.nested {
            .list-col.nested {
              width: 100%;
            }

            & + .list-col.nested {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .list-cell {
      width: auto;
      flex-grow: 0;
      flex-shrink: 0;
      line-height: 1;
      
      &.cost {
        &:not(.show) {
          opacity: 0;
          color: transparent;
          visibility: hidden;
          user-select: none;
          pointer-events: none;
        }
      }
    }

    .packer-name {
      width: 13%;
      text-transform: capitalize;
    }
    .orders-pr-hour {
      width: 9%;
    }
    .commission {
      width: 8%;
    }
    .order-count {
      width: 7%;
    }
    .product-count {
      width: 6%;
    }
    .time-pr-order {
      width: 9%;
    }
    .time-pr-product {
      width: 11%;
    }
    .cost-pr-product {
      width: 10%;
    }
    .total-time-tracked {
      width: 8%;
    }
    .total-cost-price {
      width: 7%;
    }
  }

  .loading {
    display: flex;
    position: fixed;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &-animation {
      height: 150px;
      position: relative;
    }

    &-information {
      position: relative;
    }
  }

  .sort.desc .state-wrapper {
    flex-direction: row-reverse;
  }

  .state[data-state] {
    display: block;
    transform: translateY(-2px);

    &::before {
      content: attr(data-state);
      color: $white;
      font-weight: 600;
      font-size: 0.6em;
      text-align: center;
      line-height: 20px;
    }
  }
</style>